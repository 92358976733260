import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql } from "gatsby"
import useMaxWidth from "~/hooks/useMaxWidth"

// @ts-ignore
import Logo from "~/images/Brand/logo.svg"
import BackgroundVideo from "~/components/BackgroundVideo"
import { I18nContext } from "~/components/I18n"

export const query = graphql`{
  site {
    siteMetadata {
      title
      description
      image
      author
      siteUrl
    }
  }
}
`

export default ({
  data: {
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string,
      description: string,
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
}>) => {
  useMaxWidth()

  const { locale, languages } = useContext(I18nContext)

  return <>
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: locale,
        dir: "ltr",
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      <title>{title}</title>
      <link rel="author" href="/humans.txt" />

      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}${image}`} />

      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${siteUrl}${image}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />

      <style>{`:root {
  --hiq-color-gray-1: hsl(220, 10%, 98%);
  --hiq-color-gray-2: hsl(220, 10%, 95%);
  --hiq-color-gray-3: hsl(220, 10%, 90%);
  --hiq-color-gray-4: hsl(220, 10%, 60%);
  --hiq-color-gray-5: hsl(220, 10%, 40%);
  --hiq-color-gray-6: hsl(220, 10%, 20%);
  --hiq-color-gray-7: hsl(220, 10%, 10%);
  --hiq-color-gray-8: hsl(220, 10%, 5%);
  --hiq-color-primary: white;
  --hiq-html-background-color: black;
  --hiq-body-background-color: black;
  --hiq-sidebar-background-color: transparent;
}`}</style>
    </Helmet>

    <div className="is-full-display is-opacity-decreased">
      <BackgroundVideo
        className="is-full-viewport has-blur"
        poster=""
        sources={[
          {
            src: "https://cdn.brandfire.pt/uploads/2021/10/homepage-{{width}}x{{height}}-1.mp4",
            type: "video/mp4",
          },
          {
            src: "https://cdn.brandfire.pt/uploads/2021/10/homepage-{{width}}x{{height}}-1.webm",
            type: "video/webm",
          },
        ]}
        sizes={[
          {
            width: 240,
            height: 426,
          },
          {
            width: 640,
            height: 360,
          },
        ]}
      ></BackgroundVideo>
    </div>

    <div className="is-full-display is-height-100 is-grid-center">
      <Logo id="logo-page" height="90" fill="white" />
    </div>
  </>;
}
