import React from "react"
import useWindowSize from "~/hooks/useWindowSize"

export default ({
  className,
  sources,
  poster,
  sizes,
  fallback,
}: {
  className?: string
  poster: string
  fallback?: (p: {width: number, height: number}) => React.ReactNode
  sources: {
    src: string
    type: "video/mp4" | "video/webm" | "video/ogg" | "video/avi" | "video/3gp" | "video/quicktime"
  }[]
  sizes: {
    width: number
    height: number
  }[]
}) => {
  const video = React.useRef<HTMLVideoElement>(null)

  const { width, height } = useWindowSize()

  console.log('Available sizes', sizes.map(({width, height}) => `${width} x ${height}`))
  console.log('Detected size', width, 'x', height)

  const matchsize = () => sizes.find(({width: owidth, height: oheight}) => owidth >= width && oheight >= height) || sizes[sizes.length - 1]
  const playvideo = () => {
    const v = video.current!!
    if (v && v.paused) v.play()
  }

  React.useEffect(() => {
    if (video.current) {
      video.current!!.muted = true
      video.current!!.play()
    }

    console.log('Set video muted and ask to play.');
    ['click', 'touchstart'].forEach(event => document.addEventListener(event, playvideo))

    return () => {
      if (video.current) video.current!!.pause();
      ['click', 'touchstart'].forEach(event => document.removeEventListener(event, playvideo))
    }
  }, [

  ])

  const size = matchsize()
  if (!size) return <></>

  console.log('Best-fit size', `${size.width} x ${size.height}`)

  return (<>
    <video
      key={`${size.width}x${size.height}`}
      ref={video}
      className={className || ''}
      loop
      autoPlay
      playsInline
      disablePictureInPicture
      preload="auto"
      poster={poster.replace("{{width}}", size.width.toString()).replace("{{height}}", size.height.toString())}
      style={{
        objectFit: "cover",
        opacity: 0.8,
      }}
    >
      {sources.map(({src, type}) => (
        <source
          key={type}
          src={src.replace("{{width}}", size.width.toString()).replace("{{height}}", size.height.toString())}
          type={type}
        />
      ))}
      {fallback && fallback({width: size?.width, height: size.height})}
    </video>
  </>)
}
